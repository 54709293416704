<template>
  <modal-section
    modalId="patientImportHistoryModal" 
    ref="patientImportHistoryModal" 
    class="patient-import-history-modal"
    :centered="true" 
    :closeButton="true" 
    size="lg" 
    :wide="true">
    <template 
      v-slot:title>
      {{ $t('data_import_history') }}
    </template>

    <template v-slot:body>
      <!-- List of Items, or History List -->
      <table-list
        ref="importHistoryTable"
        table-id="import-history-table"
        :table-config="tableConfig"
        :firstColumnTabbable="true"
        :total-records="patientImportHistory.length || 0"
        @onPageNumber="onPageNumber($event); loadData()"
        @onPageSize="onPageSize($event); loadData()"
        mode="remote"
      />
    </template>
  </modal-section>
</template>

<script lang="ts">
import { Component, Vue, mixins } from 'vue-facing-decorator';
import ModalSection from '@/components/shared/ModalSection.vue';
import TableList from '@/components/shared/TableList.vue';
import { i18nMessages } from '@/i18n';
import { TableConfig } from '@/types';
import { UIConfiguration } from '@/UIModels/configuration';
import { useCurrentPageStore } from '@/stores/currentPage';
import { UIRecipient } from "@/UIModels/recipient";
import { UIRecipientImportHistory } from "@/UIModels/recipients/recipientImportHistory"; 
import { RemotePaginationMixin } from "@/mixins/remote-pagination-mixin";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";

const PAGE_SIZES = [5, 10, 25];
const DEFAULT_PAGE_SIZE = PAGE_SIZES[0];

interface TableRow {
  date: string|null;
  time: string|null;
  status: string|null;
  uploadedBy: string|null;
}

@Component({
  components: {
    ModalSection,
    TableList
  },
  ...i18nMessages([
    require('@/components/recipients/_locales/PatientImportHistory.json'),
    require('@/views/importPatient/_locales/import.json'),
  ]),
})
export default class PatientImportHistory extends mixins(DateUtilsMixin, RemotePaginationMixin) {

  private isLoading = true;
  private patientImportHistory: UIRecipientImportHistory[] = [];

  mounted(): void {
    this.isLoading = false;
  }

  // Which Configuration view model are we viewing on the current page?
  // NOTE: this is shared client state from the pinia store
  get currentConfiguration(): UIConfiguration {
    const currentPageStore = useCurrentPageStore();
    return currentPageStore.configuration as UIConfiguration;
  }

  get importHistoryTable(): TableList {
    return this.$refs.importHistoryTable as TableList;
  }

  get tableRows(): TableRow[] {
    const rows: TableRow[] = this.patientImportHistory.map((item: UIRecipientImportHistory) => {
      return {
        date: this.parseDisplayDateUiFromDateTime(item.datetime) || '-',
        time: this.parseTimeUiFromDateTime(item.datetime) || '-',
        status: this.$t(item.status as string).toString() || '-',
        uploadedBy: item.uploadedBy || '-',
      };
    });
    return rows;
  }

  get tableConfig(): TableConfig {
    const tableConfig = [
      { label: `${this.$t('date')}`,
        field: 'date',
        sortable: false,
      },
      { label: `${this.$t('time')}`,
        field: 'time',
        sortable: false,
      },
      { label: `${this.$t('uploaded_by')}`,
        field: 'uploadedBy',
        sortable: false,
      },
      { label: `${this.$t('status')}`,
        field: 'status',
        sortable: false,
      },
    ];

    return {
      data: this.tableRows,
      columns: tableConfig,
      empty: this.$t('no_history').toString(),
      pagination: true,
      sortOptions: {
        enabled: false
      },
      paginationOptions: {
        enabled: true,
        perPage: this.perPage,
        mode: 'pages',
        perPageDropdown: PAGE_SIZES,
        defaultPageSize: DEFAULT_PAGE_SIZE,
        setCurrentPage: this.pageNumber,
        dropdownAllowAll: false,
        position: 'bottom'
      }
    };
  }

  // Toggle a modal based on a ref
  private toggleModal(ref: string): void {
    const targetModal = this.$refs[ref] as ModalSection;
    targetModal.toggleModal();
  }

  get uiRecipient(): UIRecipient {
    return useCurrentPageStore().currentRecipient as UIRecipient;
  }

  public async openModal(): Promise<void> {
    // refresh history
    this.toggleModal('patientImportHistoryModal'); // close modal
    this.loadData();
  }

  public async loadData(): Promise<void> {
    this.isLoading = true;
    const params = { 
      pageNumber: this.pageNumber,
      pageSize: this.perPage,
    };
    this.patientImportHistory = await UIRecipientImportHistory.loadFor(this.uiRecipient, params);
    this.isLoading = false;
  }
}
</script>
