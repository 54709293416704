import { APIRecipient, APIMeasurement, APISerology, APISerologyInterface, APIRecipientImportHistory } from '@/store/recipients/types';
import { UIRecipient } from '@/UIModels/recipient';
import { APIRoute, EP } from '@/api-endpoints';
import axios from 'axios';
import { UIError } from '@/UIModels/error';

export class UIRecipientImportHistory {
  public apiSource?: APIRecipient;
  public datetime: string|null = null;
  public status: string|null = null;
  public uploadedBy: string|null = null;

  // Define new UI view model structure
  public constructor(apiHistory: APIRecipientImportHistory|null = null) {
    if (apiHistory) this.updateFromAPIResponse(apiHistory);
  }

  // Map from API data structure to UI model structure
  public updateFromAPIResponse(apiHistory: APIRecipientImportHistory) {
    this.apiSource = apiHistory;
    this.datetime = apiHistory.created_at || null;
    this.uploadedBy = apiHistory.updated_by || null;
    this.status = apiHistory.status || null;
  }

  // Load list for specified recipient
  public static async loadFor(uiRecipient: UIRecipient, params: any): Promise<UIRecipientImportHistory[]> {
    const recipientId = uiRecipient.clientId || '';
    let result: UIRecipientImportHistory[] = [];
    const url = APIRoute(EP.recipients.import_history.index, [[':recipient_id', recipientId]]);
    await axios.get(url, { params : params }).then((response: any) => {
      const apiImportHistory: APIRecipientImportHistory[] = response?.data?.import_row_infos.entries || [];
      result = apiImportHistory.map((item: APIRecipientImportHistory): UIRecipientImportHistory => {
        return new UIRecipientImportHistory(item);
      });
    }).catch((error: any) => {
      console.warn(error);
      throw new UIError('data_import_history');
    });
    return result;
  }
}
