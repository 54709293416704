import { createApp } from 'vue';
import App from './App.vue';
import { router } from './router';
import store from './store';
import i18n, { i18nPlugin } from './i18n';
import PrimeVue from 'primevue/config';
import Tooltip from 'primevue/tooltip';
import { vMaska } from "maska/vue";
import VueDatePicker from '@vuepic/vue-datepicker';

// Create Vue 3 instance
const app = createApp({
  extends: App,
});
app.use(router);
app.use(store);
app.use(i18nPlugin);

app.mixin({
  methods: {
    // replaces this.$t with one that favours loaded translations, defers to component method if not found
    $t(key: string, choice?: any | undefined, values?: any | undefined): string {
      // check if param exists in i18n messages array, if so use it
      if (i18n.te(key)) return i18n.tc(key, choice, values);
      // otherwise fallback to this.$tc which defers to component translations
      return this.$tc(key, choice, values);
    }
  }
});

import { createPinia, PiniaVuePlugin } from 'pinia';

// Setup pinia 
app.use(PiniaVuePlugin);
const pinia = createPinia();
app.use(pinia);

// Set up jQuery
import * as jquery from 'jquery';
(window as any).jQuery = (window as any).$ = jquery;

// Set up bootstrap
import 'popper.js';
import 'bootstrap';

// Set up Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import './icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
app.component('font-awesome-icon', FontAwesomeIcon);

// Setup vue-datepicker
app.component('VueDatePicker', VueDatePicker);

// Set up styles
import './assets/scss/application.scss';

// Set up VeeValidation
import { Field as ValidationProvider, Form as ValidationObserver } from 'vee-validate';
app.component('validation-provider', ValidationProvider);
app.component('validation-observer', ValidationObserver);
app.directive('tooltip', Tooltip);

// Set up input masking
app.directive("maska", vMaska);

import axios from 'axios';

axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  return {
    ...config,
    validateStatus: function (status: any) {
      return (status >= 200 && status < 300 || status === 422) ? true : false;
    }
  };
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Ripple is an optional animation for the supported components such as buttons. 
// It is disabled by default and needs to be enabled while installing PrimeVue.
app.use(PrimeVue, {ripple: true});

// attach access_token from local storage to every api request
const access_token = localStorage.getItem('access_token') || null;
const headers = {
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
  'Access-Control-Allow-Methods': 'POST, GET, PATCH, DELETE, OPTIONS'
};
if (access_token) {
  axios.defaults.headers.common = { 'Authorization': `Bearer ${access_token}`, ...headers };
} else {
  axios.defaults.headers.common = { ...headers };
}

if (process.env.NODE_ENV !== 'production' && !process.env.VUE_APP_DISABLE_AXE) {
  const VueAxe = require('vue-axe');
  app.use(VueAxe, {
    config: {
      rules: [
        { id: 'heading-order', enabled: true },
        { id: 'label-title-only', enabled: true },
        { id:  'scope-attr-valid', enabled: false} // TODO:  Track the issue here https://github.com/vue-a11y/vue-axe/issues/4
      ]
    },
    clearConsoleOnUpdate: false
  });
}

app.mount('#afflo-app');
