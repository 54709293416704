import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "filter-section-action-row" }
const _hoisted_2 = { class: "filter-section-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_filter_component = _resolveComponent("filter-component")!
  const _component_table_toolbar = _resolveComponent("table-toolbar")!
  const _component_table_list = _resolveComponent("table-list")!
  const _component_modal_section = _resolveComponent("modal-section")!

  return (_openBlock(), _createBlock(_component_modal_section, {
    modalId: "detailModal",
    ref: "detailModal",
    class: "detail-modal",
    centered: true,
    closeButton: true,
    size: "md",
    wide: true
  }, {
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('import_details', { datetime: `${_ctx.selected.date} ${_ctx.selected.time}`, filename: _ctx.selected.fileName } )), 1)
    ]),
    body: _withCtx(() => [
      _createVNode(_component_table_toolbar, null, {
        "button-bar": _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_filter_component, {
                fieldID: "notesFilter",
                showFilter: true,
                showImportDetailsModalFilter: true,
                onSetFilters: _ctx.setFilters,
                hideSpacer: true,
                alignLeft: true
              }, null, 8, ["onSetFilters"])
            ])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_table_list, {
        ref: "importDetailTable",
        "table-id": "import-detail-table",
        "table-config": _ctx.tableDetailConfig,
        firstColumnTabbable: true,
        "total-records": _ctx.tableDetailRows.length || 0,
        onOnPageNumber: _cache[0] || (_cache[0] = ($event: any) => {_ctx.onPageNumber($event); _ctx.loadData()}),
        onOnPageSize: _cache[1] || (_cache[1] = ($event: any) => {_ctx.onPageSize($event); _ctx.loadData()}),
        mode: "remote"
      }, null, 8, ["table-config", "total-records"])
    ]),
    _: 1
  }, 512))
}