import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_table_list = _resolveComponent("table-list")!
  const _component_import_detail_modal = _resolveComponent("import-detail-modal")!
  const _component_sub_section = _resolveComponent("sub-section")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_openBlock(), _createBlock(_component_card_section, {
    "section-id": "import-file-card",
    showLegend: false
  }, {
    body: _withCtx(() => [
      _createVNode(_component_sub_section, {
        title: _ctx.$t('import_history'),
        "sub-section-id": "import-history-section"
      }, {
        contents: _withCtx(() => [
          _createVNode(_component_table_list, {
            ref: "importHistoryTable",
            "table-id": "import-history-table",
            "table-config": _ctx.tableHistoryConfig,
            onTableRowClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.importHistoryRowClick($event))),
            highlightSelection: true,
            firstColumnTabbable: true,
            "total-records": _ctx.tableHistoryRows.length || 0,
            onOnPageNumber: _cache[1] || (_cache[1] = ($event: any) => {_ctx.onPageNumber($event); _ctx.loadData()}),
            onOnPageSize: _cache[2] || (_cache[2] = ($event: any) => {_ctx.onPageSize($event); _ctx.loadData()}),
            mode: "remote"
          }, null, 8, ["table-config", "total-records"]),
          _createVNode(_component_import_detail_modal, { ref: "importDetailModal" }, null, 512)
        ]),
        _: 1
      }, 8, ["title"])
    ]),
    _: 1
  }))
}