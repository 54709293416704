import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_proto_small_bowel_information = _resolveComponent("proto-small-bowel-information")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_ctx.isProtoSmallBowelSpecificDetailsEnabled)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_card_section, { "section-id": "proto_small_bowel_specific_details" }, {
          header: _withCtx(() => [
            _createTextVNode(" Small Bowel Specific Details ")
          ]),
          body: _withCtx(() => [
            _createVNode(_component_proto_small_bowel_information)
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}