<template>
  <card-section
    section-id="import-file-card"
    :showLegend="false"
    >
    <template v-slot:body>
      <sub-section 
        :title="$t('import_file')" 
        sub-section-id="import-file-section"
        >
        <template v-slot:contents>
          <!-- Form layout -->
          <validation-observer ref="validations">
            <form-layout
              :disabled="!canSave"
              form-id="import-form">

              <template v-slot:contents>
                <div class="row">
                  <div class="standard-form-group">
                    <select-input
                      rules="required"
                      select-id="transplant_program"
                      :name="$t('transplant_program')"
                      v-model="editState.transplant_program"
                      :options="hospitalOptions"
                    />
                  </div>

                  <!-- Patient Data CSV -->
                  <div class="standard-form-group-large">

                    <validation-provider
                      rules="required"
                      ref="attachment-provider"
                      :label="$t('patient_data_csv')"
                      name="filename"
                      v-slot="{ errors }"
                      v-model="editState.filename"
                    >
                      <label for="attachment">{{$t('patient_data_csv')}} <i>*</i></label>
                      <input
                        input-id="attachment"
                        ref="fileUploader"
                        type="file"
                        class="form-control"
                        :class="{ 'is-invalid': errors[0] }"
                        @change="onAttachmentChanged($event)"
                      />
                      <div class="invalid-feedback" id="attachment-error" v-if="errors[0]">
                        <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
                        {{ translateError(errors, $t('import_title')) }}
                      </div>
                    </validation-provider>

                  </div>
                </div>
              </template>

              <template v-slot:save>
                <save-toolbar
                  :show="canSave"
                  ref="saveToolbar"
                  class="card-footer action-row temp-saving row"
                  :label="$t('import_file')"
                  :cancelButton="true"
                  @save="handleSave"
                  @cancel="handleCancel"
                  :success-text="$t('import_initiated')"
                />

                <!-- confirm modal -->
                <modal-section
                  modalId="confirmImport" 
                  ref="confirmImport" 
                  :centered="true" 
                  :closeButton="false" 
                  size="md" 
                  :wide="false">
                  <template v-slot:title>
                    {{ $t('confirm') }}
                  </template>

                  <template v-slot:body>
                    <fieldset>
                      <small v-html="$t('please_confirm_details', { transplant_program: buildTransplantCentreValue(this.editState.transplant_program), file_name: this.editState.filename })"/>
                    </fieldset>
                  </template>

                  <template v-slot:footer>
                    <div class="modal-footer-body">
                      <button 
                        type="button" 
                        data-dismiss="modal" 
                        @click="importConfirmButton"
                        class="btn btn-success">
                        {{ $t('confirm') }}
                      </button>
                      <button 
                        type="button"
                        data-dismiss="modal"
                        @click="importCancelButton"
                        class="btn btn-secondary">
                        {{ $t('cancel') }}
                      </button>
                    </div>
                  </template>
                </modal-section>

              </template>

            </form-layout>
          </validation-observer>
        </template>
      </sub-section>
    </template>
  </card-section>
</template>

<script lang="ts">
import { Getter, State } from "vuex-facing-decorator";
import { Component } from "vue-facing-decorator";
import { Vue } from "vue-facing-decorator";
import SubSection from "@/components/shared/SubSection.vue";
import { UIConfiguration } from '@/UIModels/configuration';
import { useCurrentPageStore } from '@/stores/currentPage';
import FormLayout from '@/components/shared/FormLayout.vue';
import SaveToolbar from "@/components/shared/SaveToolbar.vue";
import { parseFormErrors } from '@/utils';
import { i18nMessages } from "@/i18n";
import { IdLookup } from '@/store/validations/types';
import { SaveResult } from "@/types";
import SelectInput from "@/components/shared/SelectInput.vue";
import { Hospital } from "@/store/hospitals/types";
import { GenericCodeValue } from "@/store/types";
import { UIImportPatients, UIImportFile } from "@/UIModels/importPatients";
import CardSection from '@/components/shared/CardSection.vue';
import { Form as ValidationObserver } from 'vee-validate';
import ModalSection from '@/components/shared/ModalSection.vue';

@Component({
  components: {
    CardSection,
    SubSection,
    FormLayout,
    SaveToolbar,
    SelectInput,
    ModalSection
  },
  ...i18nMessages([
    require('@/views/importPatient/_locales/import.json'),
  ]),
})
export default class ImportFile extends Vue {
  @State(state => state.hospitals.all) hospitals!: Hospital[];

  // Getters
  @Getter('translateError', { namespace: 'utilities' }) private translateError!: (error?: any, field?: string|null) => string;

  // Editable view model for the form
  private editState = new UIImportFile();
  
  // Which Configuration view model are we viewing on the current page?
  // NOTE: this is shared client state from the pinia store
  get importPatients(): UIImportPatients {
    const currentPageStore = useCurrentPageStore();
    return currentPageStore.importPatients as UIImportPatients;
  }

  private buildTransplantCentreValue(hospitalId: string|null): string|null {
    if (!hospitalId) return '-';

    const hospitalDisplayValue = this.buildTransplantCentreFilter.find((option: GenericCodeValue) => { 
      return option.code === hospitalId; 
    });
    if (!hospitalDisplayValue) return '-';

    return hospitalDisplayValue.value;
  }

  get buildTransplantCentreFilter(): GenericCodeValue[] {
    if (!this.hospitals) return [];

    const mapped = this.hospitals.map((hospital: Hospital): GenericCodeValue => {
      return {
        code: hospital._id.$oid,
        value: hospital.program_identifier,
      };
    });
    return mapped;
  }

  get hospitalOptions(): GenericCodeValue[] {
    if (!this.hospitals) return [];

    const mapped = this.hospitals.map((hospital: Hospital): GenericCodeValue => {
      return {
        code: hospital._id.$oid,
        value: `${hospital.program_identifier} - ${hospital.hospital_name_info?.name}`,
      };
    });
    return mapped;
  }

  get canSave(): boolean {
    return this.importPatients.canSave;
  }

  // Which Configuration view model are we viewing on the current page?
  // NOTE: this is shared client state from the pinia store
  get currentConfiguration(): UIConfiguration {
    const currentPageStore = useCurrentPageStore();
    return currentPageStore.configuration as UIConfiguration;
  }

  // Reference to the form's save toolbar
  get saveToolbar(): SaveToolbar|null {
    const saveToolbar = this.$refs.saveToolbar;
    if (!saveToolbar) return null;

    return saveToolbar as SaveToolbar;
  }

  // Reference to the form validations observer
  get validations(): typeof ValidationObserver {
    return this.$refs.validations as typeof ValidationObserver;
  }

  // Process save button click event
  private async handleSave(): Promise<void> {
    this.resetFormErrors();
    this.resetSaveToolbar();

    if (this.validations) {
      // re-set the filename attribute if the file object exists
      this.editState.setFilenameFromObject();
      const result = await this.validations.validate();
      if (!result.valid) {
        if (this.saveToolbar) this.saveToolbar.startSaving();
        this.handleErrors(result);
        return;
      }
    }

    this.showConfirmModal();
  }

  private async handleSaveProcess(): Promise<void> {
    if (this.saveToolbar) this.saveToolbar.startSaving();
    const saveParams: any = { 
      selected: this.editState
    };
        
    try {
      const success: SaveResult = await this.editState.save(saveParams);
      this.handleSuccess(success);
    } catch (error: unknown) {
      this.handleErrors(error as SaveResult);
    }
  }

  // Clear selection
  private async handleSuccess(success: SaveResult): Promise<void> {
    if (this.saveToolbar) this.saveToolbar.stopSaving(success);

    this.editState = new UIImportFile();
    this.resetFileUploader();
    this.resetFormErrors();
  }

  // Process error save result
  private handleErrors(errors: SaveResult): void {
    // Derive errors for UI input fields based on API error results
    const formErrors: any = parseFormErrors(errors, this.idLookup());

    // inject api errors into vee-validate
    if (this.validations) this.validations.setErrors(formErrors);

    if (this.saveToolbar) this.saveToolbar.stopSaving(errors);
  }

  // Reset form when cancel is clicked
  public handleCancel(): void {
    this.prepareNewImport();
  }

  public prepareNewImport(): void {
    this.editState = new UIImportFile();
    this.resetFileUploader();
    this.resetFormErrors();
    this.resetSaveToolbar();
  }

  // Resets Form Errors
  private resetFormErrors(): void {
    const validations = this.$refs.validations as any;
    if (validations) validations.resetForm();
  }

  /**
    * Updates form state when Attachment files are uploaded
    *
    * @listens attachments#changed
    */
  private onAttachmentChanged(event: any) {
    if (!!this.editState && !!event.target) {{
      this.editState.importAttachments = event.target.files;

      this.editState.setFilenameFromObject();
    }}
    // reset attachment control only
    const field = this.$refs['attachment-provider'] as any;
    if (field) field.reset({ value: this.editState.filename });
  }

  private resetFileUploader(): void {
    const fileUploader = this.$refs.fileUploader as any;
    if (fileUploader) fileUploader.value = null;

    const field = this.$refs['attachment-provider'] as any;
    // reset has a bug where the value will be kept if set, so we need to set it to null
    if (field) field.reset({ value: null });
  }

  // Clears all save notifications shown by the form.
  public resetSaveToolbar(): void {
    if (this.saveToolbar) this.saveToolbar.reset();
  }

  // API response keys on the left, id for our UI on the right
  public idLookup(): IdLookup {
    const mapping: IdLookup = {
      'patient_imports.transplant_program'       : 'transplant_program',
      'patient_imports.import_csv'               : 'attachment',
    };
    return mapping;
  }

  // Toggle a modal based on a ref
  private toggleModal(ref: string): void {
    const targetModal = this.$refs[ref] as ModalSection;
    if (targetModal) targetModal.toggleModal();
  }

  private showConfirmModal(): void {
    this.toggleModal('confirmImport'); // close modal
  }

  private importConfirmButton(): void {
    this.toggleModal('confirmImport'); // close modal
    this.handleSaveProcess();
  }

  private importCancelButton(): void {
    this.toggleModal('confirmImport'); // close modal
    this.handleCancel();
  }
}
</script>
