<template>
  <div>
    <page-top>
      {{ $t('recipients') }}
    </page-top>
    <template v-if="!recipients">
      <div class="content-wrap">
        <div class="container-fluid">
          <loading-list-view />
        </div>
      </div>
    </template>
    <full-page-list-layout v-else>
      <template v-slot:content>
        <nav class="nav list-patients action-row">
          <div class="ml-auto">
            <template v-if="isImportPatientsEnabled">
              <router-link class="btn btn-success" :class="{ disabled: !canCreate }" :to="{ name: 'import' }">
                {{$t('patient_data_uploads')}}
              </router-link>
              &nbsp;
            </template>
            <router-link class="btn btn-primary" :class="{ disabled: !canCreate }" :to="{ name: 'new-recipient' }">
              {{$t('add_new_recipient')}}
            </router-link>
          </div>
        </nav>

        <table-filter
          @filterList="setSearchParams"
        />

        <div class="action-row d-flex">
          <p class="element-left">{{ $t("system_returned")}} <b>{{recipients?.count || 0}}</b> {{ $t('recipients') }}</p>

          <column-config
            v-if="columnsFilter"
            class="ml-auto"
            columnKey="recipients"
            config-id="-list-columns"
            :options="recipientsColumns"
            :hiddenOptions="hiddenOptions"
            v-model="columnsFilter.selectedColumns"
          />
        </div>
        <div class="highlight-scroll" v-if="isOverflowed">
          {{ $t('scroll_right_to_see_more') }}
          <font-awesome-icon :icon="['fas', 'chevron-right']" class="nav-caret" fixed-width />
        </div>

        <table-list
          table-id="list-recipient"
          :horizontalScroll="true"
          :listingView="true"
          mode="remote"
          :showTopScrollBar="true"
          :firstColumnTabbable="true"
          :total-records="recipients?.count || 0"
          :table-config="recipientTableConfig"
          :highlightSelection="true"
          @on-page-number="onPageNumber($event); loadData()"
          @on-page-size="onPageSize($event); loadData()"
          @on-sort-change="filterList($event)"
          @table-row-click="selectRecipient($event)"
          :isLoading="isLoading || !recipients"
          :jumpToPage="true"
        />

      </template>
    </full-page-list-layout>
  </div>
</template>

<script lang="ts">
import { mixins } from "vue-facing-decorator";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { TableConfig, ColumnOption, APIPaginatedSearchResult } from '@/types';
import { Getter, State } from 'vuex-facing-decorator';
import { ListRecipient, ListRecipientJourney } from '@/store/recipients/types';
import PageTop from '@/components/shared/PageTop.vue';
import { Component } from 'vue-facing-decorator';
import { Organ, OrganCodeValue, BloodType } from '@/store/lookups/types';
import { NumericCodeValue, GenericCodeValue } from '@/store/types';
import { Sex } from '@/store/lookups/types';
import { urlParams, isMasked } from '@/utils';
import LoadingListView from '@/components/shared/loading-skeletons/LoadingListView.vue';
import FullPageListLayout from "@/components/layouts/FullPageListLayout.vue";
import ColumnConfig from '@/components/shared/ColumnConfig.vue';
import TableList from '@/components/shared/TableList.vue';
import TableFilter from '@/components/shared/TableFilter.vue';
import { i18nMessages } from "@/i18n";
import { RemotePaginationMixin } from "@/mixins/remote-pagination-mixin";
import { useCurrentPageStore } from "@/stores/currentPage";
import { DATA_BUNDLES } from "@/UIModels/configuration/features";
import { Hospital } from "@/store/hospitals/types";

interface ColumnsFilterState {
  selectedColumns: string[];
}

interface RecipientListingRow {
  transplant_centre: string;
  registration_date: string,
  client_id: string,
  first_name: string,
  last_name: string,
  blood_type: string;
  dob: string,
  mrn: string;
  organ_code: string,
  journey_state: string,
  workflow_step: string,
  sex: string,
  checklist_completion: string;
}

// NOTE: Patient list: 10, 25 (default), 100
// See: https://shorecg.atlassian.net/wiki/spaces/AP/pages/1581383697/Paginated+Table
// TODO: TECH_DEBT: can page sizes be moved to application level somehow
const PAGE_SIZES = [10, 25, 100];
const DEFAULT_PAGE_SIZE = PAGE_SIZES[1]; // 25

@Component({
  components: {
    PageTop,
    TableList,
    TableFilter,
    ColumnConfig,
    LoadingListView,
    FullPageListLayout
  },
  ...i18nMessages([
    require('../_locales/common.json'),
    require('../../_locales/recipients/recipientsListTableColumns.json'),
    require('../../components/_locales/Organs.json'),
  ]),
})
export default class ListRecipients extends mixins(DateUtilsMixin, RemotePaginationMixin) {
  @State(state => state.pageState.currentPage.columnsFilter) columnsFilter!: ColumnsFilterState;
  @State(state => state.lookups.organ) organLookup!: Organ[];
  @State(state => state.lookups.blood_type) bloodTypeLookup!: BloodType[];
  @State(state => state.lookups.recipient_journey_phase_status) phaseStatusLookup!: GenericCodeValue[];
  @State(state => state.lookups.recipient_journey_workflow_step) workflowStepLookup!: GenericCodeValue[];
  @State(state => state.hospitals.all) hospitals!: Hospital[];

  //Getters
  @Getter('showList', { namespace: 'recipients' }) public recipients!: APIPaginatedSearchResult<ListRecipient>;
  @Getter('organName', { namespace: 'lookups' }) organName!: (organCode?: number) => string;
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;
  @Getter('sexOptions', { namespace: 'lookups' }) sexOptions!: Sex[];
  @Getter('nonExpiredOrganOptions', { namespace: 'lookups' }) nonExpiredOrganOptions!: (type?: string) => NumericCodeValue[];

  get isImportPatientsEnabled(): boolean {
    return useCurrentPageStore().configuration.features.recipientConfig.importPatients.enabled;
  }

  get isBasicNameEnabled(): boolean {
    return useCurrentPageStore().configuration.features.recipientConfig.demographics.bundles.isEnabled(DATA_BUNDLES.PatientNameBasic);
  }

  get isDateOfBirthEnabled(): boolean {
    return useCurrentPageStore().configuration.features.recipientConfig.demographics.bundles.isEnabled(DATA_BUNDLES.PatientDOBAge);
  }

  get isMRNEnabled(): boolean {
    return useCurrentPageStore().configuration.features.recipientConfig.demographics.bundles.isEnabled(DATA_BUNDLES.PatientMRN);
  }

  get isSexEnabled(): boolean {
    return useCurrentPageStore().configuration.features.recipientConfig.demographics.bundles.isEnabled(DATA_BUNDLES.PatientSex);
  }

  get isWorkflowStepEnabled(): boolean {
    return useCurrentPageStore().configuration.features.journeyConfig.journeyOverview.phaseStatus.workflowStep.enabled;
  }

  get lookupsToLoad(): string[] {
    const result = [
      'sex',
      'blood_type',
      'recipient_journey_phase_status',
    ];
    if (this.isWorkflowStepEnabled) result.push('recipient_journey_workflow_step');
    return result;
  }

  public isOverflowed = false;
  private searchParams: string|null = null;
  private sortParams: string|null = null;

  private isLoadingLookups = true;
  private isLoadingEntries = true;

  get isLoading(): boolean {
    return this.isLoadingLookups || this.isLoadingEntries;
  }

  get hiddenOptions(): string[] {
    return this.recipientsColumns.filter((column: ColumnOption) => {
      return column.hidden;
    }).map((column: ColumnOption): string => {
      return column.field;
    });
  }

  get canCreate(): boolean {
    return this.checkAllowed('/recipients', 'POST');
  }

  private async loadLookups(lookupsToLoad: string[]): Promise<void> {
    this.isLoadingLookups = true;
    await Promise.all([
      ...lookupsToLoad.map((lookup: string): Promise<any> => { return this.$store.dispatch('lookups/queueLookup', { lookup }); }),
    ]);

    this.isLoadingLookups = false;
  }

  public checkwidth() {
    this.isOverflowed = window.innerWidth < 1200;
  }

  public mounted(): void {
    this.perPage = DEFAULT_PAGE_SIZE;
    Promise.all([
      this.$store.dispatch('hospitals/load'),
      this.loadLookups(this.lookupsToLoad),
    ]).finally(() => {
      this.loadData();
      this.initializePageState();
      this.checkwidth();
      window.addEventListener('resize', this.checkwidth);
    });
  }

  private initializePageState(): void {
    // Initialize filter form state
    this.$store.commit('pageState/set', {
      pageKey: 'columnsFilter',
      value: this.buildColumnsFilter(),
    });
  }

  private buildColumnsFilter(): ColumnsFilterState {
    const columns = useCurrentPageStore().preferences.getColumns('recipients');
    return {
      selectedColumns: columns,
    };
  }

  get filteredColumns(): any[] {
    if (!this.columnsFilter || !this.columnsFilter.selectedColumns) {
      return this.recipientsColumns;
    }
    const selectedFields = this.columnsFilter.selectedColumns;
    const selectedColumns = this.recipientsColumns.filter((column: any) => {
      return selectedFields.includes(column.field);
    });
    return selectedColumns;
  }

  // Sets the search terms and sorting options
  public setSearchParams(searchParams: string) {
    this.searchParams = searchParams;

    this.filterList(null);
  }

  // Sets the search terms and sorting options
  public filterList(event: any) {
    if (event) {
      const sortParams = event?.sortParams;
      if (sortParams) this.sortParams = urlParams(event.sortParams);

      const currentPage = event.currentPage;
      if (currentPage) this.pageNumber = currentPage;
    }

    this.loadData();
  }

  /**
   * Loads recipients list
   * NOTE: custom search parameters and sort options determined by local component state
   */
  public loadData() {
    this.isLoadingEntries = true;
    const search_params = [this.searchParams || '', this.sortParams || ''].filter((p) => { return p && p.length >=0; });
    this.$store.dispatch('recipients/getList', {
        pageNumber: this.pageNumber,
        pageSize: this.perPage,
        search_params: `${search_params.length > 0 ? '&' : ''}${search_params.join('&')}`,
      }
    ).then(() => {
      this.isLoadingEntries = false;
    });
  }

  public selectRecipient(event: any) {
    if (event.row.client_id !== undefined) {
      const clientId: string = event.row.client_id.toString();
      this.$router.push({
        name: 'edit-recipient',
        params: {
          id: clientId,
        }
      });
    } else {
      console.warn('Missing recipient clientId');
      this.$router.push({name: 'list-recipients'});
    }
  }

  get buildTransplantCentreFilter(): GenericCodeValue[] {
    if (!this.hospitals) return [];

    const mapped = this.hospitals.map((hospital: Hospital): GenericCodeValue => {
      return {
        code: hospital._id.$oid,
        value: hospital.program_identifier,
      };
    });
    return mapped;
  }

  /**
   * Parse combined date/time field to be shown in table
   *
   * @param value the datetime property
   * @returns {string} string representation of date
   */
  formatDateFromDateTime(value: string|null): string {
    if (!value) return '-';
    if (isMasked(value)) return `${value}`;

    return this.parseDisplayDateUiFromDateTime(value) || '-';
  }

  /**
   * Parse date-only field to be shown in table
   *
   * @param value the date property
   * @returns {string} string representation of date
   */
  formatDate(value: string|null): string {
    if (!value) return '-';
    if (isMasked(value)) return `${value}`;

    return this.parseDisplayDateUi(value) || '-';
  }

  /**
   * Getter for the columns.
   *
   */
  get recipientsColumns(): ColumnOption[] {
    return [
      {
        label: this.$t('transplant_centre').toString(),
        field: 'transplant_centre',
        sortable: false,
        tdClass: 'nobr',
        width: '150px'
      },
      {
        label: this.$t('client_id').toString(),
        field: 'client_id',
        sortable: true,
        width: '150px'
      },
      {
        label: this.$t('recipient_registration_date').toString(),
        field: 'registration_date',
        sortable: true,
        width: '150px',
        thClass: 'vgt-left-align',
        tdClass: 'vgt-left-align',
      },
      {
        label: this.$t('first_name').toString(),
        field: 'first_name',
        sortable: true,
        width: '200px',
        hidden: !this.isBasicNameEnabled
      },
      {
        label: this.$t('last_name').toString(),
        field: 'last_name',
        sortable: true,
        width: '200px',
        hidden: !this.isBasicNameEnabled
      },
      {
        label: this.$t('dob').toString(),
        field: 'dob',
        type: 'date',
        sortable: false,
        width: '120px',
        thClass: 'vgt-left-align',
        tdClass: 'vgt-left-align',
        hidden: !this.isDateOfBirthEnabled,
      },
      {
        label: this.$t('mrn').toString(),
        field: 'mrn',
        sortable: false,
        width: '120px',
        thClass: 'vgt-left-align',
        tdClass: 'vgt-left-align',
        hidden: !this.isMRNEnabled
      },
      {
        label: this.$t('sex').toString(),
        field: 'sex',
        width: "130px",
        sortable: false,
        hidden: !this.isSexEnabled,
      },
      {
        label: this.$t('blood_type').toString(),
        field: 'blood_type',
        width: "120px",
        sortable: false
      },
      {
        label: this.$t('organ_referral').toString(),
        field: 'organ_code',
        html: true,
        tdClass: 'nobr',
        sortable: false,
        width: '160px'
      },
      {
        label: this.$t('journey_phase_and_status').toString(),
        field: 'journey_state',
        sortable: false,
        html: true,
        tdClass: 'nobr',
        width: '190px'
      },
      {
        label: this.$t('workflow_step.heading').toString(),
        field: 'workflow_step',
        sortable: false,
        html: true,
        tdClass: 'nobr',
        width: '250px',
        hidden: !this.isWorkflowStepEnabled
      },
      {
        label: this.$t('checklist_completion').toString(),
        field: 'checklist_completion',
        sortable: false,
        html: true,
        tdClass: 'nobr',
        width: '160px'
      },
    ];
  }

  /**
   * Translates any of the columns that are just codes and unreadable to a more readable format and adds it into the object
   *
   * Affects the following columns: Organ Referrals, Journey Status, Medical Status, Insurance Type
   */
  get recipientList(): RecipientListingRow[] {
    if (!this.recipients) return [];

    const recipients: ListRecipient[] = this.recipients.entries || [];
    const result = recipients.map((recipient: ListRecipient): RecipientListingRow => {
      const journeys: ListRecipientJourney[] = recipient.journeys || [];
      let journeyOrgans: string[] = [];
      let journeyStates: string[] = [];
      let workflowSteps: string[] = [];

      if (journeys.length > 0) {
        journeyOrgans = journeys.map((journey: ListRecipientJourney) => {
          const organCode = journey.organ_code;
          if (organCode == null) {
            return 'N/A';
          }
          const organName: string|undefined = this.organName(organCode) || undefined;
          return organName ? this.$t(organName) : 'Unknown';
        });
        journeyStates = journeys.map((journey: ListRecipientJourney) => {
          return this.buildJourneyStateDisplayValue(journey);
        });
        workflowSteps = journeys.map((journey: ListRecipientJourney) => {
          return this.buildWorkflowStepDisplayValue(journey);
        });
      }

      return {
        transplant_centre: this.buildTransplantCentreValue(recipient) || '-',
        registration_date: this.formatDateFromDateTime(recipient.registration_date) || '-',
        client_id: recipient.client_id,
        first_name: recipient.first_name || '-',
        last_name: recipient.last_name || '-',
        blood_type: recipient.blood_type || '-',
        dob: this.formatDate(recipient.dob) || '-',
        mrn: recipient.mrn || '-',
        organ_code: journeyOrgans.join('<br /> ') || '-',
        journey_state: journeyStates.filter(Boolean).join('<br /> ') || '-',
        workflow_step: workflowSteps.filter(Boolean).join('<br />') || '-',
        sex: this.buildSexDisplayValue(recipient) || '-',
        checklist_completion: this.buildChecklistCompletionValue(recipient) || '-',
      };
    });
    return result;
  }

  private buildTransplantCentreValue(recipient: ListRecipient): string|null {
    const hospitalId = recipient.hospital_id?.$oid;
    if (!hospitalId) return '-';

    const hospitalDisplayValue = this.buildTransplantCentreFilter.find((option: GenericCodeValue) => { return option.code === hospitalId; });
    if (!hospitalDisplayValue) return '-';

    return hospitalDisplayValue.value;
  }

  private buildChecklistCompletionValue(recipient: ListRecipient): string|null {
    const journeys: ListRecipientJourney[] = recipient.journeys || [];
    if (journeys.length === 0) return null;

    const journeyChecklistCompletions = journeys.map((journey: ListRecipientJourney): string => {
      const fraction: number|null = journey.checklist_task_completed_fraction;
      if (fraction === null ) return '-';

      return this.$t('checklist_completion_count', {
        percent:   Math.floor(fraction * 100),
        completed: journey.checklist_task_completed_count,
        total:     journey.checklist_task_count,
      });
    });
    return journeyChecklistCompletions.join('<br /> ');
  }

  private buildSexDisplayValue(recipient: ListRecipient): string|null {
    return (this.sexOptions || []).find((sex: Sex) => { return sex.code === recipient.sex; })?.value || null;
  }

  // Build user-facing Journey Phase - Status display string
  private buildJourneyStateDisplayValue(journey: ListRecipientJourney): string {
    if (!journey.phase) return this.$t('phase_status.not_applicable').toString(); // 'N/A';

    const phaseOptions = this.phaseStatusLookup || [];
    const phaseLookup = phaseOptions.find((phaseOption: GenericCodeValue) => {
      return phaseOption.code == journey.phase;
    });
    if (!phaseLookup) return this.$t('phase_status.unknown').toString();

    const phaseName = phaseLookup.value;
    if (!journey.status) return phaseName;

    const statusOptions = phaseLookup.sub_tables?.statuses || [];
    const statusLookup = statusOptions.find((statusOption: GenericCodeValue) => {
      return statusOption.code == journey.status;
    });
    if (!statusLookup) return phaseName;
    const statusName = statusLookup.value;
    const result = this.$t('phase_status.display', { phaseName, statusName }).toString();
    return result;
  }

  // Build user-facing Workflow Step display string
  private buildWorkflowStepDisplayValue(journey: ListRecipientJourney): string {
    if (!journey.step) return this.$t('workflow_step.none').toString(); // '--'

    const lookupEntries = this.workflowStepLookup || [];
    const lookupEntry = lookupEntries.find((stepOption: GenericCodeValue) => {
      return stepOption.code == journey.step;
    });
    if (!lookupEntry) return this.$t('workflow_step.unknown').toString();

    return lookupEntry.value;
  }

  get recipientTableConfig(): TableConfig {
    return {
      data: this.recipientList,
      columns: this.filteredColumns, // switch to function to make sure options rebuilt each time
      empty: this.$t('empty_recipient_listing').toString(),
      createButton: false,
      pagination: true,
      sortOptions: {
        enabled: true
      },
      paginationOptions: {
        enabled: true,
        perPage: this.perPage,
        mode: 'pages',
        perPageDropdown: PAGE_SIZES,
        defaultPageSize: DEFAULT_PAGE_SIZE,
        setCurrentPage: this.pageNumber,
        dropdownAllowAll: false,
        nextLabel: '',
        prevLabel: '',
        rowsPerPageLabel: 'Results per page',
        position: 'bottom'
      }
    };
  }
}
</script>
